import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useEffect, useRef, useState } from "react"

import Link from "gatsby-link";


import * as styles from "../scss/privacy.module.scss"
import * as btnStyles from "../components/buttons/buttons.module.scss"
import * as indexStyles from "../scss/indexPage.module.scss"



const Privacy = () => {
  const shapeFirst = useRef(null)
  const shapeSecond = useRef(null)
  const shapeThird = useRef(null)

  useEffect(() => {
    
    const shapeFistOffsetTop = shapeFirst.current.offsetTop
    
    const shapeSecondOffsetTop = shapeSecond.current.offsetTop
     
    window.addEventListener("scroll", () => {
      if (shapeFirst.current) {
        shapeFirst.current.style.top =
          shapeFistOffsetTop + window.scrollY / 3 + "px"
        shapeSecond.current.style.top =
          shapeSecondOffsetTop - window.scrollY / 3 + "px"
      } else {
        window.removeEventListener("scroll", () => {})
      }
    })

  })


return(
  <Layout>
    {/* <SEO title="Privacy" /> */}
    <SEO
                    title="Datenschutz"
                    description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
                    keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
                    author= "spectory"
      />
    <div className={styles.responsiveWrapper}>
    <div className={styles.hero}>
      <div  className={`clearfix container ${styles.container}`} style ={{margin:0,padding:0}}>
        <div className={styles.intro}>
          <h1>
           Datenschutzerklärung
          </h1>
          <br/>
          <div>
          Wir haben diese Datenschutzerklärung (Fassung 29.06.2019-221096992) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 und dem Datenschutzgesetz (DSG) zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
          <br/>
          <br/>
          Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
          <br/>
          <br/>
          Automatische Datenspeicherung
          <br/>
          Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.
          <br/>
          <br/>
          Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie
          die Adresse (URL) der aufgerufenen Webseite
          <br/>
          Browser und Browserversion
          <br/>
          das verwendete Betriebssystem
          <br/>
          die Adresse (URL) der zuvor besuchten Seite (Referrer URL)
          <br/>
          den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
          <br/>
          Datum und Uhrzeit
          <br/>
          in Dateien (Webserver-Logfiles).
          <br/>
          <br/>
          In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
          Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 f DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass berechtigtes Interesse daran besteht, den fehlerfreien Betrieb dieser Webseite durch das Erfassen von Webserver-Logfiles zu ermöglichen.
          <br/>
          <br/>
          Cookies
          <br/>
          Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.
          <br/>
          Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.
          <br/>
          <br/>
          Was genau sind Cookies?
          <br/>
          <br/>
          Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
          <br/>
          <br/>
          Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch anderer Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem „Hirn“ Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.
          <br/>
          <br/>
          Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.
          <br/>
          <br/>
          Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.
          <br/>
          <br/>
          So können zum Beispiel Cookie-Daten aussehen:
          <br/>
          <br/>
          Name: _ga
          <br/>
          Ablaufzeit: 2 Jahre
          <br/>
          Verwendung: Unterscheidung der Webseitenbesucher
          <br/>
          Beispielhafter Wert: GA1.2.1326744211.152221096992
          <br/>
          Ein Browser sollte folgende Mindestgrößen unterstützen:
          <br/>
          <br/>
          Ein Cookie soll mindestens 4096 Bytes enthalten können
          <br/>
          Pro Domain sollen mindestens 50 Cookies gespeichert werden können
          <br/>
          Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
          <br/>
          Welche Arten von Cookies gibt es?
          <br/>
          <br/>
          Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.
          <br/>
          <br/>
          Man kann 4 Arten von Cookies unterscheiden:
          <br/>
          <br/>
          Unbedingt notwendige Cookies
          <br/>
          Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
          <br/>
          <br/>
          Funktionelle Cookies
          <br/>
          Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.
          <br/>
          <br/>
          Zielorientierte Cookies
          <br/>
          Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.
          <br/>
          <br/>
          Werbe-Cookies
          <br/>
          Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.
          <br/>
          <br/>
          Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.
          <br/>
          <br/>
          Wie kann ich Cookies löschen?
          <br/>
          <br/>
          Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.
          <br/>
          <br/>
          Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:
          <br/>
          <br/>
          Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          <br/>
          <br/>
          Safari: Verwalten von Cookies und Websitedaten mit Safari
          <br/>
          <br/>
          Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben
          <br/>
          <br/>
          Internet Explorer: Löschen und Verwalten von Cookies
          <br/>
          <br/>
          Microsoft Edge: Löschen und Verwalten von Cookies
          <br/>
          <br/>
          Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder „Cookies deaktivieren Chrome“ im Falle eines Chrome Browsers oder tauschen das Wort „Chrome“ gegen den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.
          <br/>
          <br/>
          Wie sieht es mit meinem Datenschutz aus?
          <br/>
          <br/>
          Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG).
          <br/>
          <br/>
          Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht zurückscheuen, empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der Internet Engineering Task Force (IETF) namens „HTTP State Management Mechanism“.
          <br/>
          <br/>
          Speicherung persönlicher Daten
          <br/>
          Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.
          <br/>
          <br/>
          Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.
          <br/>
          <br/>
          Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.
          <br/>
          <br/>
          Die Rechtsgrundlage besteht nach Artikel 6 Absatz 1 a DSGVO (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.
          <br/>
          <br/>
          Auswertung des Besucherverhaltens
          <br/>
          In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.
          <br/>
          <br/>
          Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
          <br/>
          <br/>
          TLS-Verschlüsselung mit https
          <br/>
          Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.
          <br/>
          <br/>
          MailChimp Datenschutzerklärung
          <br/>
          Wir versenden Newsletter mit MailChimp und verwenden auf dieser Webseite Funktionen des Newsletter Dienstes MailChimp der Firma The Rocket Science Group, LLC, 675 Ponce de Leon Ave NE, Suite 5000, Atlanta, GA 30308 USA um Newsletter-Anmeldungen zu erfassen.
          <br/>
          <br/>
          Allgemeine Informationen zu MailChimp
          <br/>
          <br/>
          Die Rocket Science Group LLC (MailChimp) unterhält Online-Plattformen, die es unseren Nutzern ermöglichen, in Kontakt mit ihren Abonnenten zu bleiben, in erster Linie per E-Mail. Sie erlauben Benutzern, E-Mail-Adressen und andere Informationen zum Abonnentenprofil, wie z. B. Name, physische Adresse und andere demografische Informationen, in die MailChimp Datenbank hochzuladen. Diese Informationen werden verwendet, um E-Mails zu senden und die Verwendung bestimmter anderer MailChimp-Funktionen für diese Benutzer zu ermöglichen. In Übereinstimmung mit den veröffentlichten Datenschutzrichtlinien teilt MailChimp einige Informationen mit Drittanbietern, um die Dienste bereitzustellen und zu unterstützen, die MailChimp den Nutzern anbietet. MailChimp teilt auch einige Informationen mit Werbepartnern von Drittanbietern, um die Bedürfnisse und Interessen der Nutzer besser zu verstehen, damit relevantere Inhalte und zielgerichtete Werbung für diese Nutzer und andere Nutzer bereitgestellt werden können.
          <br/>
          <br/>
          Newsletter Anmeldung
          <br/>
          <br/>
          Wenn Sie auf unserer Webseite für unseren Newsletter anmelden, werden die eingegebenen Daten bei MailChimp gespeichert.
          <br/>
          <br/>
          Löschung Ihrer Daten
          <br/>
          <br/>
          Sie können Ihre Zustimmung für den Erhalt unseres Newsletters jederzeit innerhalb der empfangenen E-Mail per Klick auf den Link im unteren Bereich entziehen. Wenn Sie sich mittels Klick auf den Abmeldelink abgemeldet haben, werden Ihre Daten bei MailChimp gelöscht.
          <br/>
          <br/>
          Newsletter Auswertung
          <br/>
          <br/>
          Wenn Sie einen Newsletter über MailChimp erhalten, werden Informationen wie IP-Adresse, Browsertyp und E-Mail-Programm gespeichert um uns Informationen zur Performance unseres Newsletters zu geben. MailChimp kann mittels den in den HTML-E-Mails integrierten Bildern namens Web Beacons (Details finden Sie auf https://kb.mailchimp.com/reports/about-open-tracking) feststellen ob die E-Mail angekommen ist, ob sie geöffnet wurde und ob Links angeklickt wurden. All diese Informationen werden auf den Servern von MailChimp gespeichert, nicht auf dieser Webseite.
          <br/>
          <br/>
          MailChimp ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG. Mehr über den Einsatz von Cookies bei MailChimp erfahren Sie auf https://mailchimp.com/legal/cookies/, Informationen zum Datenschutz bei MailChimp (Privacy) können Sie auf https://mailchimp.com/legal/privacy/ nachlesen.
          <br/>
          <br/>
          MailChimp Auftragsdatenverarbeitung Vertrag
          <br/>
          Wir haben mit MailChimp einen Vertrag über die Auftragsdatenverarbeitung (Data Processing Addendum) abgeschlossen. Dieser Vertrag dient zur Absicherung Ihrer persönlichen Daten und stellt sicher, dass sich MailChimp an die geltenden Datenschutzbestimmungen hält und Ihre persönlichen Daten nicht an Dritte weitergibt.
          <br/>
          <br/>
          Mehr Informationen zu diesem Vertrag finden Sie auf http://mailchimp.com/legal/forms/data-processing-agreement/.
          <br/>
          <br/>
          Google Fonts Lokal Datenschutzerklärung
          <br/>
          Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver, nicht Googles Server, eingebunden. Dadurch gibt es keine Verbindung zu Server von Google und somit auch keine Datenübertragung bzw. Speicherung.
          <br/>
          <br/>
          Was sind Google Fonts?
          <br/>
          <br/>
          Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die die Google LLC zur freien Verwendung bereitstellt. Mit Google Fonts könnte man die Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zum Google-Server zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.
          <br/>
          <br/>
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=221096992.
          <br/>
          <br/>
          Google Fonts Datenschutzerklärung
          <br/>
          Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite.
          <br/>
          <br/>
          Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.
          <br/>
          <br/>
          Was sind Google Fonts?
          <br/>
          <br/>
          Google Fonts (früher Google Web Fonts) ist ein interaktives Verzeichnis mit mehr als 800 Schriftarten, die die Google LLC zur freien Verwendung bereitstellt.
          <br/>
          <br/>
          Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen. Somit können wir sie frei verwenden, ohne dafür Lizenzgebühren zu zahlen.
          <br/>
          <br/>
          Warum verwenden wir Google Fonts auf unserer Webseite?
          <br/>
          <br/>
          Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sogenannte sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser ( Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod).
          <br/>
          <br/>
          Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können. Nach dem Art. 6 Abs. 1 f lit. F DSGVO stellt das bereits ein „berechtigtes Interesse“ an der Verarbeitung von personenbezogenen Daten dar. Unter „berechtigtem Interesse“ versteht man in diesem Fall sowohl rechtliche als auch wirtschaftliche oder ideelle Interessen, die vom Rechtssystem anerkannt werden.
          <br/>
          <br/>
          Welche Daten werden von Google gespeichert?
          <br/>
          <br/>
          Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um die Erfassung, Speicherung und Verwendung von Endnutzerdaten auf das zu reduzieren, was für eine effiziente Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.
          <br/>
          <br/>
          Google Fonts speichert CSS- und Font-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google die Beliebtheit der Schriften feststellen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. BigQuery ist ein Webservice von Google für Unternehmen, die große Datenmengen bewegen und analysieren wollen.
          <br/>
          <br/>
          Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie IP-Adresse, Spracheinstellungen, Bildschirmauflösung des Browsers, Version des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.
          <br/>
          <br/>
          Wie lange und wo werden die Daten gespeichert?
          <br/>
          <br/>
          Anfragen für CSS-Assets speichert Google einen Tag lang auf Ihren Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.
          <br/>
          <br/>
          Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.
          <br/>
          <br/>
          Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
          <br/>
          <br/>
          Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=221096992 kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.
          <br/>
          <br/>
          Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=221096992. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig (beinahe unmöglich), von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.
          <br/>
          <br/>
          Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
          <br/>
          <br/>
          Google Maps Datenschutzerklärung
          <br/>
          Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite.
          <br/>
          <br/>
          Durch die Nutzung der Funktionen dieser Karte werden Daten an Google übertragen. Welche Daten von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
          <br/>
          <br/>
          Google Analytics Datenschutzerklärung
          <br/>
          Wir verwenden auf dieser Website Google Analytics der Firma Google LLC (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) um Besucherdaten statistisch auszuwerten. Dabei verwendet Google Analytics zielorientierte Cookies.
          <br/>
          <br/>
          Cookies von Google Analytics
          <br/>
          <br/>
          _ga
          <br/>
          Ablaufzeit: 2 Jahre
          <br/>
          Verwendung: Unterscheidung der Webseitenbesucher
          <br/>
          Beispielhafter Wert: GA1.2.1326744211.152221096992
          <br/>
          _gid
          <br/>
          Ablaufzeit: 24 Stunden
          <br/>
          Verwendung: Unterscheidung der Webseitenbesucher
          <br/>
          Beispielhafter Wert: GA1.2.1687193234.152221096992
          <br/>
          _gat_gtag_UA_ Ablaufzeit: 1 Minute
          <br/>
          Verwendung: Wird zum Drosseln der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ .
          <br/>
          Beispielhafter Wert: 1
          <br/>
          Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter http://www.google.com/analytics/terms/de.html bzw. unter https://support.google.com/analytics/answer/6004245?hl=de.
          <br/>
          <br/>
          Pseudonymisierung
          <br/>
          <br/>
          Unser Anliegen im Sinne der DSGVO ist die Verbesserung unseres Angebotes und unseres Webauftritts. Da uns die Privatsphäre unserer Nutzer wichtig ist, werden die Nutzerdaten pseudonymisiert. Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 EU-DSGVO Abs 1 lit a (Einwilligung) und/oder f (berechtigtes Interesse) der DSGVO.
          <br/>
          <br/>
          Deaktivierung der Datenerfassung durch Google Analytics
          <br/>
          <br/>
          Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) können Website-Besucher verhindern, dass Google Analytics ihre Daten verwendet.
          <br/>
          <br/>
          Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: https://tools.google.com/dlpage/gaoptout?hl=de
          <br/>
          <br/>
          Google Analytics Zusatz zur Datenverarbeitung
          <br/>
          Wir haben mit Google einen Direktkundenvertrag zur Verwendung von Google Analytics abgeschlossen, indem wir den “Zusatz zur Datenverarbeitung” in Google Analytics akzeptiert haben.
          <br/>
          <br/>
          Mehr über den Zusatz zur Datenverarbeitung für Google Analytics finden Sie hier: https://support.google.com/analytics/answer/3379636?hl=de&utm_id=ad
          <br/>
          <br/>
          Google Analytics IP-Anonymisierung
          <br/>
          Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google Analytics implementiert. Diese Funktion wurde von Google entwickelt, damit diese Webseite die geltenden Datenschutzbestimmungen und Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn diese eine Speicherung der vollständigen IP-Adresse untersagen. Die Anonymisierung bzw. Maskierung der IP findet statt, sobald die IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
          <br/>
          <br/>
          Mehr Informationen zur IP-Anonymisierung finden Sie auf https://support.google.com/analytics/answer/2763052?hl=de.
          <br/>
          <br/>
          Rechte laut Datenschutzgrundverordnung
          <br/>
          Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen Datenschutzgesetzes (DSG) grundsätzlich die folgende Rechte zu:
          <br/>
          <br/>
          Recht auf Berichtigung (Artikel 16 DSGVO)
          <br/>
          Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
          <br/>
          Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
          <br/>
          Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
          <br/>
          Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
          <br/>
          Widerspruchsrecht (Artikel 21 DSGVO)
          <br/>
          Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
          <br/>
          Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter https://www.dsb.gv.at/ finden.
          <br/>
          <br/>
          Google Analytics Berichte zu demografischen Merkmalen und Interessen
          <br/>
          Wir haben in Google Analytics die Funktionen für Werbeberichte eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen erfahren Sie auf https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad.
          <br/>
          <br/>
          Sie können die Nutzung der Aktivitäten und Informationen Ihres Google Kontos unter “Einstellungen für Werbung” auf https://adssettings.google.com/authenticated per Checkbox beenden.
          <br/>
          <br/>
          Google Analytics Deaktivierungslink
          <br/>
          Wenn Sie auf folgenden Deaktivierungslink klicken, können Sie verhindern, dass Google weitere Besuche auf dieser Webseite erfasst. Achtung: Das Löschen von Cookies, die Nutzung des Inkognito/Privatmodus ihres Browsers, oder die Nutzung eines anderen Browsers führt dazu, dass wieder Daten erhoben werden.
          <br/>
          <br/>
          Google Analytics deaktivieren
          <br/>
          Eingebettete Social Media Elemente Datenschutzerklärung
          <br/>
          Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.
          <br/>
          Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
          <br/>
          Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:
          <br/>
          <br/>
          Instagram-Datenschutzrichtlinie: https://help.instagram.com/519522125107875 
          <br/>
          Für YouTube gilt die Google Datenschutzerklärung: https://policies.google.com/privacy?hl=de
          <br/>
          Facebook-Datenrichtline: https://www.facebook.com/about/privacy
          <br/>
          Twitter Datenschutzrichtlinie: https://twitter.com/de/privacy
          <br/>
          Facebook Datenschutzerklärung 
          <br/>
          Wir verwenden auf dieser Webseite Funktionen von Facebook, einem Social Media Network der FIrma Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.
          <br/>
          Welche Funktionen (Soziale Plug-ins) Facebook bereitstellt, können Sie auf https://developers.facebook.com/docs/plugins/ nachlesen.
          <br/>
          Durch den Besuch unserer Webseite können Informationen an Facebook übermittelt werden. Wenn Sie über ein Facebook-Konto verfügen, kann Facebook diese Daten Ihrem persönlichen Konto zuordnen. Sollten Sie das nicht wünschen, melden Sie sich bitte von Facebook ab.
          <br/>
          Die Datenschutzrichtlinien, welche Informationen Facebook sammelt und wie sie diese verwenden finden Sie auf https://www.facebook.com/policy.php.
          <br/>
          <br/>
          Twitter Datenschutzerklärung 
          <br/>
          Wir verwenden auf dieser Webseite Funktionen von Twitter, einem Social Network der Firma Twitter International Company, One Cumberland Place, Fenian Street, Dublin 2 D02 AX07, Irland.
          <br/>
          <br/>
          Dadurch können auch personenbezogene Daten an Twitter übertragen werden. Wir überwachen diesen Datenaustausch nicht und speichern nichts darüber.
          <br/>
          <br/>
          Wenn Sie mehr über die Nutzung Ihrer Daten durch Twitter wissen möchten, finden Sie alle Informationen in der Twitter Datenschutzrichtline auf https://twitter.com/de/privacy.
          <br/>
          <br/>
          Sollten Sie ein Konto bei Twitter haben, können Sie Ihre Datenschutzeinstellungen in den Konto- Einstellungen unter https://twitter.com/settings/account ändern.
          <br/>
          <br/>
          Instagram Datenschutzerklärung 
          <br/>
          Wir verwenden auf unserer Webseite Funktionen des Social Media Netzwerks Instagram der Firma Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA.
          <br/>
          <br/>
          Mit den Funktionen zum Einbetten von Instagram-Inhalten (Embed-Funktion) können wir Bilder und Videos anzeigen.
          <br/>
          <br/>
          Durch den Aufruf von Seiten die solche Funktionen nutzen werden Daten (IP-Adresse, Browserdaten, Datum, Uhrzeit, Cookies) an Instagram übermittelt, gespeichert und ausgewertet.
          <br/>
          <br/>
          Sollten Sie ein Instagram-Konto haben und angemeldet sein, werden diese Daten Ihrem persönlichen Konto und den darin gespeicherten Daten zugeordnet.
          <br/>
          <br/>
          Die Datenschutzrichtlinien, welche Informationen Instagram sammelt und wie sie diese verwenden finden Sie auf https://help.instagram.com/519522125107875.
          <br/>
          <br/>
          Google AdSense Datenschutzerklärung <br/>
          Wir verwenden auf diese Webseite Google AdSense, dem Anzeigenprogramm der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA).
          <br/>
          <br/>
          Dabei werden Daten zu den angezeigten Werbemitteln und Ihre IP-Adresse möglicherweise an Server in die USA übermittelt und dort ausgewertet.
          <br/>
          <br/>
          In AdSense sollen Cookies bessere Werbung ermöglichen, die Cookies enthalten keine personalisierten Daten. Google AdSense sendet nach jeder Impression, jedem Klick und jeder anderen Aktivität, die zu einem Aufruf der Google Adsense-Server führt, ein Cookie an den Browser. Wenn der Browser das Cookie akzeptiert, wird es dort gespeichert.
          <br/>
          <br/>
          Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der Website erhalten. Web Beacons sind kleine Grafiken, die eine Logdatei-Aufzeichnung und eine Logdateianalyse ermöglichen, die für statistische Auswertungen für das Online-Marketing verwendet werden.
          <br/>
          <br/>
          Cookies deaktivieren
          <br/>
          <br/>
          Sie können jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, löschen oder Cookies deaktivieren. Die Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “cookies löschen chrome” oder “cookies deaktivieren chrome” im Falle eines Chrome Browsers oder tauschen das Wort “chrome” gegen den Namen Ihres Browsers, z. B. edge, firefox, safari aus.
          <br/>
          <br/>
          Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per Browsereinstellung deaktivieren, können manche Funktionen und Seiten nicht wie erwartet funktionieren.
          <br/>
          <br/>
          Personalisierte Werbung deaktivieren
          <br/>
          <br/>
          Wenn Sie ein Google Konto haben, können Sie auf der Seite https://adssettings.google.com/authenticated personalisierte Werbung deaktivieren. Auch im Falle der Deaktivierung der Personalisierung von Anzeigen sehen Sie möglicherweise weiterhin Anzeigen auf der Grundlage von Faktoren wie Ihrem ungefähren Standort, der aus Ihrer IP-Adresse abgeleitet wird, dem Browsertyp sowie den kürzlich verwendeten Suchbegriffen.
          <br/>
          <br/>
          Welche Daten von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.
          <br/>
          <br/>
          Facebook-Pixel Datenschutzerklärung
          <br/>
          Wir verwenden auf dieser Webseite das Facebook Pixel von Facebook, einem Social Media Network der Firma Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland.
          <br/>
          <br/>
          Der auf dieser Seite implementierte Code kann das Verhalten der Besucher auswerten, welche von einer Facebook-Werbung auf diese Webseite gelangt sind. Dies kann zur Verbesserung von Facebook-Werbeanzeigen genutzt werden und diese Daten werden von Facebook erfasst und gespeichert. Die erfassten Daten sind für uns nicht einsehbar sondern nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Durch den Einsatz des Facebook-Pixel-Codes werden auch Cookies gesetzt.
          <br/>
          <br/>
          Durch die Nutzung des Facebook-Pixels wird der Besuch dieser Webseite Facebook mitgeteilt, damit Besucher auf Facebook passende Anzeigen zu sehen bekommen. Wenn Sie ein Facebook-Konto besitzen und angemeldet sind, wird der Besuch dieser Webseite Ihrem Facebook-Benutzerkonto zugeordnet.
          <br/>
          <br/>
          Wie das Facebook-Pixel für Werbekampagnen genutzt wird, erfahren Sie auf https://www.facebook.com/business/learn/facebook-ads-pixel.
          <br/>
          <br/>
          Sie können Ihre Einstellungen für Werbeanzeigen in Facebook auf https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen verändern, sofern Sie in Facebook angemeldet sind. Auf http://www.youronlinechoices.com/de/praferenzmanagement/ können Sie Ihre Präferenzen hinsichtlich nutzungsbasierter Online-Werbung verwalten. Sie können dort viele Anbieter auf einmal deaktivieren oder aktivieren oder die Einstellungen für einzelne Anbieter vornehmen.
          <br/>
          <br/>
          Mehr Informationen zur Datenrichtlinie von Facebook finden Sie auf https://www.facebook.com/policy.php.
          <br/>
          <br/>
          Amazon-Partnerprogramm Datenschutzerklärung
          <br/>
          Wir sind Teilnehmer am Amazon Partnerprogramm welches die Platzierung von Amazon-Werbeanzeigen und Partnerlinks auf Webseiten ermöglicht. Durch diese Platzierung von Amazon Werbung wird eine Werbekostenerstattung erzielt. 
          <br/>
          Um die Herkunft von Bestellungen nachvollziehen zu können, setzt Amazon Cookies ein. 
          <br/>
          Verantwortliche Stelle im Sinne der Datenschutzgesetze sind die Amazon Europe Core S.à.r.l., die Amazon EU S.à.r.l, die Amazon Services Europe S.à.r.l. und die Amazon Media EU S.à.r.l., alle vier ansässig 5, Rue Plaetis, L-2338 Luxemburg sowie Amazon Instant Video Germany GmbH, Domagkstr. 28, 80807 München. Als Datenverarbeiter wird in ihrem Auftrag die Amazon Deutschland Services GmbH, Marcel-Breuer-Str. 12, 80807 München, tätig.
          <br/>
          Die Datenschutzrichtlinien, welche Informationen Amazon sammelt und wie sie diese verwenden finden Sie auf https://www.amazon.de/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=3312401.
          <br/>
          <br/>
          Quelle: Erstellt mit dem Datenschutz Generator von firmenwebseiten.at in Kooperation mit gutetipps.at
          </div>
            
        </div>
        <div ref={shapeFirst} className={styles.shapeFirst}></div>
        <div ref={shapeSecond} className={styles.shapeSecond}></div>
        <div ref={shapeThird} className={styles.shapeThird}></div>
      </div>
    </div>
    
    <div  style ={{height:40, backgroundColor: "#E4F8EF" , width:"100%",position:"absolute",bottom:0,left:0,display:"block"}}></div>
    </div>
  </Layout>
  )
}

export default Privacy